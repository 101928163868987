/* eslint-disable no-param-reassign */

// XXX - We can remove this file and depend on numbox/apollo once web depends on
// it as well.

import cloneDeep from 'lodash/cloneDeep';
import reject from 'lodash/reject';
import get from 'lodash/get';

export function produce<T>(obj: T, mutator: (arg0: T) => void): T {
  const copy = cloneDeep(obj);
  mutator(copy);
  return copy;
}

export function getOrElse<T>(
  fn: () => T | null | undefined,
  defaultValue: T,
): T {
  try {
    const value = fn();
    if (value === null || value === undefined) {
      return defaultValue;
    }
    return value;
  } catch {
    return defaultValue;
  }
}

export function removeEdgeById(edges: any[], id: string): any[] {
  return reject(edges, edge => get(edge, 'node.id') === id);
}

export function removeEdgeFromConnection(connection: any, id: string): any {
  return produce(connection, c => {
    c.edges = removeEdgeById(c.edges, id);
  });
}
