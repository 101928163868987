import head from 'lodash/head';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';

const _ = { findIndex, head };
/*
  Given an array of objects, a key, and a value,
  find the object with key equal to the value,
  and return either the element following or element preceding
  (if last in list).
 */
// @ts-ignore
export const findNextElement = (arr, key, value) => {
  if (!_.head(arr)) {
    // 0 elements in array - there is no next
    return null;
  }
  // @ts-ignore
  const index = _.findIndex(arr, elem => elem[key] === value);

  if (arr.length === 1 && index !== -1) {
    // Array only had one element left and it matched - no 'next' element.
    return null;
  }

  if (index === -1) {
    return null;
  }

  if (index === arr.length - 1) {
    // Element is last in the list - return preceeding
    return arr[arr.length - 2];
  }

  return arr[index + 1];
};
// @ts-ignore
export function dynamicNestedSort(parentProp, property) {
  let sortOrder = 1;
  let prop = property;

  if (property[0] === '-') {
    sortOrder = -1;
    prop = property.substr(1);
  }
  // @ts-ignore
  return function mySort(a, b) {
    let result = 0;

    const aVal = a[parentProp][prop];
    const bVal = b[parentProp][prop];

    if (aVal < bVal) {
      result = -1;
    } else if (aVal > bVal) {
      result = 1;
    }

    return result * sortOrder;
  };
}

export const arrayMove = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number,
): T[] => {
  const newArray = [...array];
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
  if (startIndex >= array.length || startIndex < 0) {
    return newArray;
  }
  const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
  const element = newArray[startIndex];
  newArray.splice(fromIndex, 1);
  newArray.splice(endIndex, 0, element);
  return newArray;
};

/**
 * Compares two arrays for equality regardless of element order
 * @param array1 First array to compare
 * @param array2 Second array to compare
 * @returns true if arrays contain the same elements (regardless of order), false otherwise
 */
export const areArraysEqual = <T>(array1: T[], array2: T[]): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = [...array1].sort();
  const sortedArray2 = [...array2].sort();

  return isEqual(sortedArray1, sortedArray2);
};
