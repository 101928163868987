import capitalize from 'lodash/capitalize';

export const generateFullName = (
  firstName: string,
  lastName: string,
  middleName?: string,
) =>
  [capitalize(firstName), capitalize(middleName), capitalize(lastName)]
    .filter(Boolean)
    .join(' ');
